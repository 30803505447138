export const REGION_TYPE = {
  DOM: "DOM",
  INT: "INT",
};

export const PACKAGE_TYPE = {
  HOTEL: "2",
};

export const TRIP_TYPE = {};

export const TRIP_MODE = {
  HOTELS: "8",
};

export const TRIP_PAGE_TYPE = {
  HOTELS: "Hotels",
  HOTEL_DESCRIPTION: "Hotel description",
  TRIP: "Trip",
};

export const HOTEL_CATEGORY = {
  DOMESTIC: "Domestic hotel",
  INTERNATIONAL: "International hotel",
};
