export const notificaitonType = {
  default: "default",
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
};

export const addOnNotificationTimeout = 400;
export const removeNotificationTimeout = 5000;
export const deleteNotificationTimeout =
  removeNotificationTimeout + addOnNotificationTimeout;
